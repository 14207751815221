<template>
  <b-modal
    id="modal-answers-deleted"
    body-class="pt-2"
    hide-footer
    size="xl"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Fotos Eliminadas</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <section>
      <b-carousel
        id="carousel-answers-deleted"
        v-model="slide"
        controls
        indicators
        :interval="0"
        style="text-shadow: 1px 1px 2px #333"
        background="#ababab"
      >
        <!-- slides with image -->
        <b-carousel-slide
          v-for="currentImage in answersDeleted"
          :key="currentImage.id"
        >
          <template #img>
            <img
              style="height: auto !important; max-height: 700px !important"
              class="d-block mx-auto"
              :src="currentImage.image"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="mx-auto text-center">
        <p class="mt-3" v-if="getCurrentAnswer && getCurrentAnswer.deleted_at">
          Rechazado el:
          <br />
          <small class="font-weight-bold">
            {{
              moment(getCurrentAnswer.deleted_at).format(
                "DD/MM/YYYY HH:mm [hrs]"
              )
            }}
          </small>
        </p>
        <p v-if="getCurrentAnswer && getCurrentAnswer.deleted_by">
          Rechazada por:
          <br />
          <small class="font-weight-bold">
            {{
              getCurrentAnswer.deleted_by.first_name
                ? `${getCurrentAnswer.deleted_by.first_name} ${getCurrentAnswer.deleted_by.last_name}`
                : "Super Administrador"
            }}
          </small>
        </p>
      </div>
    </section>
    <br />
    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  props: {
    answersDeleted: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
      loading: false,
      loadingMarkReplaced: false,
      slide: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getCurrentAnswer() {
      return this.answersDeleted[this.slide]
        ? this.answersDeleted[this.slide]
        : null;
    },
  },
  methods: {
    ...mapActions({
      retrieveAnswer: "question/retrieveAnswer",
      sendMarkAnswerReplaced: "question/markAnswerReplaced",
    }),
    emitReplacementImage() {
      this.$emit("replaceCurrrentImage", this.getCurrentAnswer.id);
    },
    ifAnswerIsUploadedByUserAuth() {
      const currentImage = this.getCurrentAnswer;
      if (currentImage) {
        const { place_user } = currentImage;
        return place_user.customuser.id === this.currentUser?.id;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-answers-deleted");
    },
    openModalAnswerReplacement(answer) {
      this.$emit("emitOpenModalAnswerReplacement", answer);
    },
  },
};
</script>

<style></style>
