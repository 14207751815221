<template>
  <b-sidebar
    width="800px"
    shadow
    backdrop
    backdrop-variant="dark"
    id="questions-sidebar"
  >
    <template #default="{ hide }">
      <h4>Listado de preguntas</h4>
      <section>
        <div
          v-if="!questions.length"
          class="d-flex align-items-center justify-content-center mt-5"
        >
          <span class="mr-3">Cargando</span>
          <b-spinner
            type="grow"
            variant="warning"
            class="text-center"
          ></b-spinner>
        </div>
        <div class="accordion" role="tablist" v-if="questions.length">
          <item-sidebar-question
            @closeSidebarAndGetQuestion="
              emitCloseSidebarAndGetQuestion($event, hide)
            "
            v-for="question in wrappedQuestions"
            :key="question.id"
            :id="question.id"
            :statusApproved="question.status_approved"
            :type="question.question_type.name"
            :toggleId="`accordion-${question.id}`"
            :name="`${question.name}`"
            :order="`${question.order}`"
            :childs="question.child"
            :parent="question.parent"
          >
          </item-sidebar-question>
        </div>
      </section>
    </template>
  </b-sidebar>
</template>

<script>
import ItemSidebarQuestion from "./ItemSidebarQuestion.vue";
import { verifyRole } from "@/mixins/role";
import { mapGetters } from "vuex";

export default {
  mixins: [verifyRole],
  components: { ItemSidebarQuestion },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    emitCloseSidebarAndGetQuestion(question_id, hideEvent) {
      hideEvent();
      this.$emit("closeSidebarAndGetQuestion", question_id);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    wrappedQuestions() {
      console.log("this.question", this.questions)
      if (
        this.currentUser &&
        this.checkIfHaveRole(this.currentUser.rol, [
          this.ROLES.coordinator,
          this.ROLES.superadmin,
        ])
      ) {
        return this.questions;
      }
      return this.questions.filter((item) => {
        return !item.disbled_for_this_place;
      });
    },
  },
};
</script>

<style></style>
